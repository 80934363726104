
import Vue from "vue";

export default Vue.extend({
  name: "SkillRate",
  props: {
    name: String,
    rate: Number,
  },
});
